export const validateEmailString = (email) => {
  const regexp = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return Boolean(email.match(regexp));
}

export const isNameValid = (name) => name.length > 0;
export const isEmailValid = (email) => validateEmailString(email);
export const isPhoneValid = (phone) => phone.length > 0;
export const isDescriptionValid = (description) => description.length > 0;
export const isLinkToCVValid = (link) => link.length > 0;

export const highLightInput = (id, isValid, message) => {
  try {
    document.getElementById(id).classList.add(isValid ? 'valid-input' : 'invalid-input');
    document.getElementById(id).classList.remove(isValid ? 'invalid-input' : 'valid-input');
  } catch (error) {
    console.log(error);
  }
}

export const removeNonASCIICharacters = (string) => string.replace(/[^\x00-\x7F]/g, "");
export const doesStringContainNonASCII = (string) => Boolean(string.match(/[^\x00-\x7F]/g));


export const addClassToReactTelInputByParentBlock = (id) => {
  try {
    const phoneInputs = document.getElementById(id).getElementsByClassName('');
    for (let input of phoneInputs) {
      input.classList.add('-' + id);
    }
  } catch (error) {
    console.log(error)
  }

}

export const createIdFromString = (string) => {
  return string.toLowerCase().replace(/\W/g, '-')
}

export const toggleClasses = (element, removeClass, addClass) => {
  element.classList.add(addClass)
  try {
    element.classList.remove(removeClass)
  } catch (error) {
    console.log(error);
  }
}

export const scrollToElement = (id) => {
  try {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  } catch (error) {
    console.log(error);
  }
}

export const scrollToElementFromEvent = (event) => {
  event.preventDefault();
  const elementId = event.target.getAttribute('href').substring(1);
  scrollToElement(elementId);
};

export const scrollToElementCenter = (id) => {
  try {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  } catch (error) {
    console.log(error);
  }
}
