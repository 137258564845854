import React from 'react';
import {contents} from "../../containers/CaseStudiesPage/contents";
import {createIdFromString} from "../../utils/helpers";
import {NavLink} from "react-router-dom";

const CaseStudy = () => {

  const cases = contents.map(section => {
    return section.title;
  })

  const casesItems = cases.map((text, key) => {
    return <div className={`cases-item`} key={key}>
      <NavLink rel="preload" to={{
        pathname: `/case-studies`,
        hash: '#section-' + createIdFromString(text) + "/attractor"
      }}
               className={`cases-link cases-link-${key + 1}`}>
        <h5>{text}</h5>
      </NavLink>
    </div>
  })

  return (
    <div className="case-study-block wow fadeInUp"
         data-wow-duration='0.8s'
    >
      <div className="container">
        <h4>Case Studies</h4>
      </div>
      <div className="cases-box">
        {casesItems}
      </div>
    </div>
  );
};

export default CaseStudy;
