import React from 'react';
import MediumHeader from "../UI/MediumHeader/MediumHeader";
import LargeHeader from "../UI/LargeHeader/LargeHeader";

const WhatWeDo = () => {
  const items = [
    'MVP for Startups',
    'Saas systems',
    'Social networks development',

    'Navigation and discovery apps',
    'Fintech application development',
    'Business intelligence systems',

    'VR for Enterprise',
    'E-Commerce systems and eMarketplaces',
    'HRM and CRM'
  ]

  const renderedItems = items.map((item, key) => {
    return <div className={`what-we-do-item wow fadeInUp`}
                data-wow-delay={`${0.08 * key}s`}
                data-wow-offset="120"
                data-wow-duration='0.6s'
                key={key}>
      <span/><p>{item}</p>
    </div>
  })
  return (
    <div id="what-we-do">
      <div className="container">
        <MediumHeader text='What we do?'/>
        <LargeHeader
          text="We provide custom software development and IT outsourcing services. Our developers build custom software for individuals, startups, and small to medium business"/>
        <div className="what-we-do-items-box">
          {renderedItems}
        </div>
      </div>

    </div>
  );
};

export default WhatWeDo;
