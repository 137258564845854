import React from 'react';

const LargeHeader = (props) => {
  return (
    <h2 className='large-header'>
      {props.text}
    </h2>
  );
};

export default LargeHeader;
