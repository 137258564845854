import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom'
import ContactFormMaterial from "../ContactForm/ContactForm";
import {ABOUT, CASE_STUDIES, MAIN, MAIN_HIRE, PROCESSES} from "../../constants";

const LetsTalk = ({btnWidth}) => {


  useEffect(() => {
    const script = document.createElement("script");
    script.src = 'https://widget.clutch.co/static/js/widget.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, [])

  const location = useLocation();
  let blockTitle;
  switch (location.pathname) {
    case MAIN || MAIN_HIRE:
      blockTitle = 'Get a quote';
      break;
    case CASE_STUDIES:
      blockTitle = 'Hire dedicated developers for your project';
      break;
    case PROCESSES:
      blockTitle = 'What\'s your question?';
      break;
    case ABOUT:
      blockTitle = 'Let\'s talk about your project';
      break;
    default:
      blockTitle = 'Get a quote';
      break;
  }



  return (
    <div id="lets-talk"
         className="lets-talk-block wow fadeInUp"
         data-wow-duration='0.8s' id="footer"
    >
      <span className="lets-talk-bg"/>
      <div className="container">
        <div id="contact-us" className="lets-talk-info">
          <h4>{blockTitle}</h4>
          <a href="tel:+1 855 333 6827" className="lets-talk-contact lets-talk-phone">+1 855 333 6827</a>
          <a target="_blank" href="https://t.me/Attractorsoftware" rel="noreferrer"
             className="lets-talk-contact lets-talk-tg">attractorsoftware</a>
          <div className="link-box">
            <a href="https://www.facebook.com/AttractorSoftware" className="promo-link promo-link-fb"
               target='_blank'
               rel='noreferrer noopener'/>
            <a href="http://instagram.com/attractorsoftware" className="promo-link promo-link-ig"
               target='_blank'
               rel='noreferrer noopener'/>
            <a href="https://www.linkedin.com/company/attractor-software-llc/" className="promo-link promo-link-in"
               target='_blank'
               rel='noreferrer noopener'/>
            {/*<a href="#" className="promo-link promo-link-tw"/>*/}
            <div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="1" data-height="50"
                 data-darkbg="1" data-clutchcompany-id="556332"/>
          </div>

          <a href="#" className="footer-signature text_margin text_width">© Attractor Software, 2012-{new Date().getFullYear()}</a>
        </div>
        <div className="lets-talk-form">
          <ContactFormMaterial btnWidth={btnWidth} parentBlockName="lets-talk"/>
        </div>
      </div>
    </div>
  );
}

export default LetsTalk;
