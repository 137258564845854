import React, {useEffect} from 'react';
import {Helmet} from "react-helmet-async";

import Promo from "../../components/Promo/Promo";
import WhatWeDo from "../../components/WhatWeDo/WhatWeDo";
import AboutUsParts from "../../components/AboutUsParts/AboutUsParts";
import Technology from "../../components/Technology/Technology";
import CaseStudy from "../../components/CaseStudy/CaseStudy";
import Pricing from "../../components/Pricing/Pricing";
import {NavbarSentinel} from "../../components/MainNavbar/MainNavbar";
import {HOST_URL} from "../../constants";

const Main = () => {

  useEffect(() => {
    window.history.pushState('', '', '/app-developers-for-hire');

  }, [])

  return (
    <div className='main-page'>

      <Helmet>
        <meta property="og:title" content="Attarctor Software"/>
        <meta property="og:description" content="A high quality software development"/>
        <meta property="og:url" content={HOST_URL}/>
      </Helmet>

      <Promo/>
      <NavbarSentinel/>
      <WhatWeDo/>
      <AboutUsParts/>
      <Technology/>
      <CaseStudy/>
      <Pricing/>
    </div>
  );
};

export default Main;
