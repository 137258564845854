import React from 'react';
import {NavLink} from "react-router-dom";

const Technology = () => {
  const technologies = [
    {
      range: 'Frontend',
      stack: ['React', 'Redux', 'Angular']
    },
    {
      range: 'Backend',
      stack: ['Python', 'Php', 'Laravel', 'Ruby', 'Ruby on rails', 'Java', 'JS', 'Node.js', 'Golang']
    },
    {
      range: 'Project management',
      stack: ['Atlassian Jira', 'Slack', 'Trello', 'Github']
    },
    {
      range: 'Mobile',
      stack: ['Kotlin', 'Flutter', 'Swift', 'Android studio']
    },
    {
      range: 'Design',
      stack: ['Figma', 'Sketch', 'Adobe Illustrator', 'Adobe Photoshop']
    },
    {
      range: 'Infrastructure',
      stack: ['AWS', 'Digital ocean', 'Kubernetes', 'Ansible', 'GitlabCI', 'Terraform', 'Jenkins', 'Bamboo',
        'CircleCI', 'TravisCI', 'Elk', 'Sentry', 'Prometheus', 'Bitbucket', 'Apache NiFI', 'Kafka', 'Yandex ClickHouse', 'Apache hadoop', 'SIP: freeswitch, asterisk', 'CI/CD', 'Docker', 'Kubeflow']
    },
  ]

  const renderTechnologyItem = (item, key) => {
    const itemName = item
      .replace(/\s/g, '-')
      .replace(/\./g, '-')
      .replace(/:/g, '')
      .replace(/,/g, '')
      .replace(/\//g, '-')
      .toLowerCase();

    return <p
      className={`technology-item technology-item-${itemName}`}
      key={key}
    >
      {item}
    </p>
  }

  const technologyBoxes = technologies.map((technology, key) => {
    const technologyItems = technology.stack.map((item, index) => {
      return renderTechnologyItem(item, index);
    })
    let title = <h5>{technology.range}</h5>;
    if (technology.range === 'Project management') {
      title = <h5>Project
        <p className="absolute-text">management</p>
      </h5>
    }
    return <div
      className={`wow fadeInUp technology-box technology-box-${technology.range.replace(/\s/g, '-').toLowerCase()}`}
      key={key}
      data-wow-delay={`${0.08 * key}s`}
      data-wow-offset="120"
      data-wow-duration='0.8s'>
      <span/>
      {title}
      {technologyItems}
    </div>
  })

  return (
    <div className="technology-block">
      <div className="container">
        <div className="technology-block-info">
          <h4>Technology</h4>
          <p className="technology-text">Attractor Software delivers expertise in the following areas:</p>
          <p className="learn-more learn-more-desktop">
            Learn more about work processes, planning and communications on the <NavLink to="/processes" exact={true}
                                                                                         className="standard-link">processes
            page</NavLink>.
          </p>
        </div>

        <div className="technologies">
          {technologyBoxes}
        </div>

        <p className="learn-more learn-more-mobile">
          Learn more about work processes, planning and communications on the <NavLink to={{
          pathname: `/processes`,
        }} className={'standard-link'}>
          processes page
        </NavLink>.
        </p>
      </div>

    </div>
  );
};

export default Technology;
