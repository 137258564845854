import React, {useEffect, Suspense} from 'react';
import './App.scss';
import Main from "./containers/Main/Main";
import {Route, Switch} from "react-router-dom";
import {HelmetProvider} from 'react-helmet-async';
import LetsTalk from './components/LetsTalk/LetsTalk';
import MainNavbar from "./components/MainNavbar/MainNavbar";
import WOW from 'wowjs';
import "./assets/wow-animation/animate.min.css";
import {ABOUT, CASE_STUDIES, MAIN, PROCESSES} from "./constants";
import Loading from "./components/loading/Loading";
const Processes = React.lazy(() => import('./containers/Processes/Processes'));
const AboutUs = React.lazy(() => import('./containers/AboutUs/AboutUs'));
const CaseStudiesPage = React.lazy(() => import('./containers/CaseStudiesPage/CaseStudiesPage'));


function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
      mobile: false
    }).init();
  }, [])



  const loading = <Loading/>

  return (
    <HelmetProvider>
      <div className="App" id="app">
        <MainNavbar/>
        <Switch>
          <Route path={ABOUT}>
            <Suspense fallback={loading}>
            <AboutUs/>
            </Suspense>
          </Route>
          <Route path={PROCESSES}>
            <Suspense fallback={loading}>
              <Processes/>
            </Suspense>
          </Route>
          <Route path={CASE_STUDIES}>
            <Suspense fallback={loading}>
            <CaseStudiesPage/>
            </Suspense>
          </Route>


          <Route path={MAIN}>
            <Suspense fallback={loading}>
              <Main/>
            </Suspense>
          </Route>
        </Switch>
        <LetsTalk btnWidth={true}/>

      </div>
    </HelmetProvider>
  );
}

export default App;
