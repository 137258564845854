import React from 'react';

const MediumHeader = (props) => {
  return (
    <h4 className='medium-header'>
      {props.text}
    </h4>
  );
};

export default MediumHeader;
