import React from "react";
import {
  ANDROID,
  ANGULAR,
  FLUTTER,
  GOLANG,
  IOS,
  JS,
  KUBEFLOW,
  KUBERNETES,
  NODE,
  PHP,
  PYTHON,
  REACT,
  RUBY
} from "../../constants";
import mvp1 from '../../assets/images/case-studies/portfolio-bgs/mvp-1.png'
import mvp2 from '../../assets/images/case-studies/portfolio-bgs/mvp-2.png'
import mvp3 from '../../assets/images/case-studies/portfolio-bgs/mvp-3.png'
import mvp4 from '../../assets/images/case-studies/portfolio-bgs/mvp-4.png'
import navigation1 from '../../assets/images/case-studies/portfolio-bgs/navigation-1.png'
import navigation2 from '../../assets/images/case-studies/portfolio-bgs/navigation-2.png'
import vr1 from '../../assets/images/case-studies/portfolio-bgs/vr-1.png'
import saas1 from '../../assets/images/case-studies/portfolio-bgs/saas-1.png'
import saas2 from '../../assets/images/case-studies/portfolio-bgs/saas-2.png'
import saas3 from '../../assets/images/case-studies/portfolio-bgs/saas-3.png'
import saas4 from '../../assets/images/case-studies/portfolio-bgs/saass-4.png'
import fintech1 from '../../assets/images/case-studies/portfolio-bgs/fintech-1.png'
import social1 from '../../assets/images/case-studies/portfolio-bgs/social-1.png'
import social2 from '../../assets/images/case-studies/portfolio-bgs/social-2.png'
import social3 from '../../assets/images/case-studies/portfolio-bgs/social-3.png'
import ecommerce1 from '../../assets/images/case-studies/portfolio-bgs/ecommerce-1.png'
import ecommerce2 from '../../assets/images/case-studies/portfolio-bgs/ecommerce-2.png'
import ecommerce3 from '../../assets/images/case-studies/portfolio-bgs/ecommerce-3.png'
import business1 from '../../assets/images/case-studies/portfolio-bgs/business-1.png'
import hrm1 from '../../assets/images/case-studies/portfolio-bgs/hrm-1.png'
import hrm2 from '../../assets/images/case-studies/portfolio-bgs/hrm-2.png'
import hrm3 from '../../assets/images/case-studies/portfolio-bgs/hrm-3.png'
import hrm4 from '../../assets/images/case-studies/portfolio-bgs/hrm-4.jpg'


export const contents = [
  {
    title: 'MVP for Startups',
    projects: [
      {
        title: 'Development of governmental data collection service',
        backgroundImage: mvp1,
        location: 'Canada',
        stack: [REACT, PHP],
        description: <div>
          <p><span>Challenge: </span>To create a secure tool for Canada's government for collecting reliable and
            comprehensive data on the return on social investments, performing complex calculations, and generating
            reports based on that data. It should have included four microservices — data collector, logic model
            builder, de-identification data service, and Centralized Authentication Services.</p>
          <p><span>Result: </span>With the help of data collection service, beneficiaries of state funds can supply
            their data, being collected via various methods, e.g., surveys, interviews, and focus groups, into a single
            repository and save it in an identified format. Instead of sending reports with data on the social impact
            via email, they can send it directly to the collection service. A convenient categorization and storage of
            records, as well as other functions of the platform helps public officials and respondents save time, which
            they can now spend on data collection and reports preparation.
            A logical model constructor allows users to create a structure for intervention or initiatives. The
            technical design provides an analysis of the social impact of investments.
            Intervention Example: House 30% of Toronto's homeless population over the next five years.
            Initiative Example: Significantly reduce the number of childhood deaths from malnutrition.</p>
        </div>
      },
      {
        title: 'Cryptocurrency Startup',
        backgroundImage: mvp2,
        location: 'Germany',
        stack: [PYTHON, REACT],
        description: <div>
          <p><span>Challenge: </span>To create a dashboard for the funding platform, apply a referral program for
            investment platforms, develop authorization service, and build UI/UX design.</p>
          <p><span>Result: </span>The system provides an ability to monitor investments for companies and private users.
            The system is integrated with cryptocurrency wallets, WebMoney services (PayPal), and manual investment
            tools.</p>
          <p>Each user can earn rewards by bringing in new members according to the referral system rules.</p>
          <p>We have developed an algorithm for calculating rewards and displaying the calculations interactively.</p>
          <p>The system was integrated with a third-party service for checking mandatory data for each user.</p>
          <p>We have also created and implemented a centralized authorization service.</p>
        </div>
      },
      {
        title: 'Video Editing App',
        backgroundImage: mvp3,
        location: 'USA',
        stack: [FLUTTER, PYTHON],
        description: <div>
          <p><span>Client: </span>The client is a seed-stage startup from the US. They used no-code tools to create
            a proof of concept for their video editing app. The result left them dissatisfied with the lack of
            reliability and customisation. The client’s team didn’t have any developers, so they decided to find
            an app development team.</p>
          <p><span>Challenge: </span>The purpose of this app was to provide a full-featured video editing tool that
            was quick to access, portable and easy to use. The client’s vision was to empower users and help them
            get creative whenever inspiration strikes. The app needed to be lightweight and have all the necessary
            features in one cohesive design.</p>
          <p><span>Result: </span>The client and our team worked closely together during the onboarding stage to
            learn about their vision and internal processes. After clarifying product requirements, we discussed
            how Flutter could help them achieve their goals and offered to build their mobile app using the
            cross-platform SDK.</p>
          <p>Once we had a solid understanding of the product requirements and architecture, our cross-platform
            developers started building the mobile app’s prototype using the Flutter framework. We used Scrum
            for product testing and introduced Agile development principles to keep client input up-to-date
            throughout the development process.</p>
          <p>During our joint work, we built a product based on the vision of our clients. The MVP was delivered
            in 4 weeks and published on both App Store and Google Play shortly after it was finished.</p>
        </div>
      },
      {
        title: 'Fitness App',
        backgroundImage: mvp4,
        location: 'USA',
        stack: [FLUTTER, PYTHON],
        description: <div>
          <p><span>Client: </span>This project was for a US-based gym chain. The client already had a website with
            such features as video classes and training schedule management. However, they were unhappy with usage
            metrics and lack of interactivity. They also wanted to help their users gather and analyze smart watch
            data and give better recommendations based on this data.</p>
          <p><span>Challenge: </span>The technical and design requirements of this project were quite extensive.
            In short, our client wanted to replicate the functionality of their website and add push notifications,
            smart watch and analytics integrations. Also, it was necessary to test smart watch integration with
            several wearables brands to ensure everything works correctly.</p>
          <p><span>Result: </span>Even though we developed a fitness app for the first time, our project estimate
            proved to be correct. To achieve that, we did excessive research and kept close contact with the client.
            They had a clear idea of what the app should look like, and we were able to deliver just as they
            envisioned it.</p>
          <p>As we used Flutter, our team decided to bundle the iOS app with a watchOS companion app to provide
            users with the best training experience.</p>
          <p>We delivered the MVP on schedule and published it on both App Store and Google Play for the clients.</p>
        </div>
      },
    ]
  },
  {
    title: 'Navigation and Discovery apps',
    projects: [
      {
        title: 'Taxi service app',
        backgroundImage: navigation1,
        location: 'Central Asia',
        stack: [PYTHON, IOS, ANDROID, GOLANG],
        description: <div>
          <p><span>Challenge: </span>Develop a software application system for a taxi service. The system is to include
            user modules for managers, operators, drivers, individual clients, and legal entity clients.
            Clients should be able to search, call, and pay for a taxi, based on the client's starting location and the
            destination point selected on the map within the mobile application, or the website.
          </p>
          <p><span>Result: </span>In the developed system, customers have the ability to create and track orders through
            an iOS and Android mobile applications, a website, and via SMS. Operators can receive calls directly through
            the operator's interface and track all orders that are currently in the system. Drivers can receive and
            fulfill orders from customers and operators through a mobile application on Android. Managers can monitor
            the work of all divisions of the service, generate financial reports, and manage the system’s functions.
            Corporate clients have the ability to set up and manage a list of their employees, who are authorized to use
            the corporate account to pay for trips.</p>
        </div>
      },
      {
        title: 'Сheck in App with Geo Tags',
        backgroundImage: navigation2,
        location: 'Netherlands',
        stack: [FLUTTER, PYTHON],
        description: <div>
          <p><span>Client: </span>The client is a board game company from the Netherlands. They wanted to create
            an app to use as a marketing tool. The idea was to highlight gaming shops and cafes where people are
            playing their games and make recommendations based on their interests.</p>
          <p><span>Challenge: </span>The client proposed to use Foursquare as a reference for the desired result.
            We helped them to formulate requirements for other app features, such as gamification, recommendations,
            and game catalog.</p>
          <p>The client also asked us to include a QR code scanner in the app functionality. They wanted to print QR
            codes inside game boxes and provide discounts via the app to increase its adoption.</p>
          <p><span>Result: </span>Our team was very excited to work with the client on such a unique project. We had
            already built a geolocation-based mobile app for a taxi service. However, working with social features
            was new for our team.</p>
          <p>We created all the necessary design and technical guidelines for the app. The project included both an
            Android and iOS version.</p>
          <p>We implemented a recommendation algorithm based on the user's play history. Our team also implemented
            gamification mechanics so users could earn rewards for check-ins and games recommendations.</p>
          <p>With 6 weeks’ time and a small team, we’ve developed an MVP that is fully functioning and available
            on both App Store and Google Play.</p>
        </div>
      },
    ]
  },
  {
    title: 'VR for Enterprise',
    projects: [
      {
        title: 'Virtual-Estate dealing platform',
        backgroundImage: vr1,
        location: 'Germany',
        stack: [PYTHON, ANGULAR],
        description: <div>
          <p><span>Challenge: </span>The customer saw their mission in realizing the ambitious idea of creating a
            virtual world, where a real business could take place. The virtual world should offer realistic options,
            merchants should be able to sell, buyers should be able to walk, choose, and purchase.
          </p>
          <p><span>Result: </span>The platform's economy is based on a referral structure. It has the functionality for
            buying various products and distributing bonuses and commissions for the purchased products among members
            per referral structures, and based on the users’ ranks in this structure.</p>
          <p>The company's products are virtual estates across a virtual planet. We have developed a coordinate grid
            functionality and the ability to load highly detailed images of the Earth and its parts on a map. This
            functionality is very flexible and allows an administrator to select individual plots of land and customize
            them according to specific parameters. In turn, a user can choose any available land of a desired square
            footage, buy it for further construction on it, or rent it out. There is a massive logic, built into the
            platform, aimed at making the virtual world as comfortable and ergonomic as possible.
          </p>
          <p>As a separate service, a single authorization system was integrated. It was done to have a single point of
            authorization and connect new services to it.</p>
          <p>The project’s email service can customize a letter in the system using a given template, and the admin also
            has an option to select the desired trigger for dispatch.
            PDF invoices report a successful transaction in the dashboard system — an official electronic document is
            created, where all the data on the user's purchase is registered. This document is sent automatically to the
            user by email.
          </p>
        </div>
      },
    ]
  },
  {
    title: 'SaaS',
    projects: [
      {
        title: 'Web-based technical surveying and reporting system',
        backgroundImage: saas1,
        location: 'USA',
        stack: [FLUTTER, PYTHON],
        description: <div>
          <p><span>Challenge: </span>To create a system for inspecting municipal water pipes and sewers. The system
            would allow inspectors to examine water quality in various facilities, register discovered violations, send
            notifications to customers, and retrieve reports on previously found violations from a centralized database.
          </p>
          <p><span>Result: </span>The system was built and successfully launched to production. It has a client database
            for water and sewage services, allowing an authorized manager to record inspection results and send
            notifications to code violators. The system is integrated with PayPal to accept payments from customers. The
            system is accessible via web and iPad application.</p>
        </div>
      },
      {
        title: 'BSS survey',
        backgroundImage: saas2,
        location: 'USA',
        stack: [IOS, ANDROID, PYTHON],
        description: <div>
          <p><span>Challenge: </span>Develop software for conducting inspections of public water systems. An Inspector
            should be able to use the software to enter the data into the system after checking the condition of a
            backflow prevention device. If internet connection is not available, the application must be able to locally
            save the data that an Inspector enters and send it to the server, as soon as Internet connection is
            restored. If any problems are identified, a water utility organization should be able to contact the owner
            of the inspected facility.</p>
          <p><span>Result: </span>The system is designed for inspections of industrial water suppliers in various US
            cities. The system's logic: an Inspector from the city public water system checks the facility (house or
            production connected to this water system), finds violations, and enters the inspection results into the
            system. After that, the public water system sends a letter to the facility owner, stating that he needs to
            install or test protection against contaminated water getting back into the pipes. The owner finds a
            certified plumber, who then performs the installation/test and logs the results of his work into the system.
          </p>
        </div>
      },
      {
        title: 'E-commerce tool with integration of the Veeqo app',
        backgroundImage: saas3,
        location: 'Great Britain',
        stack: [RUBY, JS],
        description: <div>
          <p><span>Challenge: </span>Veeqo is an e-commerce tool that helps multi-platform retailers sell on Amazon,
            eBay, Shopify, WooCommerce, and Magento.</p>
          <p>Orders unloading usually requires printing a variety of documents. Veeqo users had to click through various
            prompts at least ten times to have a necessary file printed to complete the sale of an item and send it to a
            buyer. Our task was to simplify the process with a one-click printing. Each type of paperwork sends commands
            to different parts of the system and requires a particular kind of printer (regular printer, thermal
            printer, etc.) and various other options depending on a task: print online or save and print later. Our
            additional job was to integrate the tool with USB scales that would automatically send the information on an
            item's weight to the application.
          </p>
          <p><span>Result: </span>We integrated the Veeqo app with PrintNode, a service that manages printers and USB
            scales in warehouses. Now every Veeqo user can customize the printing of standard types of documents and
            custom templates, modify and save personal print settings. They can select a document, set a default printer
            for it, and remotely print it with a single click. We did a similar integration for USB scales.
          </p>
        </div>
      },
      {
        title: 'Data processing platform',
        backgroundImage: saas4,
        location: 'USA',
        stack: [PYTHON, JS, KUBERNETES, KUBEFLOW],
        description: <div>
          <p><span>Challenge: </span>Creation of several platforms and environments, solving the problem of accessing
            computing power and research data by data scientists.
          </p>
          <p><span>Result: </span>We have created a platform that has solved the problems of accessing tools, computing
            power, the environment, and analyzed data by data scientist experts. Each specialist can now analyze data
            using our utilities in an isolated environment, located in cloud computing services (AWS and on-prem
            servers).
          </p>
          <p>We have analyzed, customized, and tuned the Kubeflow platform, per customer's requirements to provide a
            more promising and flexible system for data science experts’ use.
            We have created an automated system for versioning settings for clusters, based on the helm. The system
            makes archive settings for each installed version, which allows a user to control the environment's
            predictability and reproducibility.
          </p>
        </div>
      },
    ]
  },
  {
    title: 'Fintech application  development',
    projects: [
      {
        title: 'Fintech application development',
        backgroundImage: fintech1,
        location: 'Kazakhstan',
        stack: [RUBY],
        description: <div>
          <p><span>Challenge: </span>In this project, Attractor Software worked jointly with the customer's team of
            developers. From the beginning, Tengri Bank planned to create a web tool that would enable getting bank
            services through the Internet. This solution would help clients run their businesses more efficiently, and
            individuals wouldn't have to go to a bank branch, stand in line, and fill out excessive paperwork. The
            developers' team was tasked with creating an electronic wallet to give clients easy access to various
            payments from utility bills to online shopping.</p>
          <p><span>Result: </span>The developers’ team built a beta version of an application that meets the bank's
            requirements. Since April 2017, the app has been available to all Tengi Bank clients. Tengri Wallet is a
            payment system of Tengri Bank integrated with</p>
          <ul>
            <li>The internal bank system</li>
            <li>A processing company</li>
            <li>The national payment system</li>
            <li>SMS provider</li>
          </ul>
          <p>Application users pay with an electronic currency backed by the issuing bank. The system allows tracking
            purchases and electronic money sales by individuals, suppliers, and electronic money sellers. One of the
            system's advantages is its ability to respond to questions such as how much electronic money is in the
            system and its amount in the bank account, which issues real money. Interaction of the Tengri system with
            the national payment system is enabled by using the electronic signature. The project's architecture is
            based on asynchronously interacting services broken down and packed into Docker containers. The process of
            uninterrupted delivery was set up. The bank employees received the product as a set of Docker containers
            with instructions for deploying the system and its launch in the production environment.
          </p>
        </div>
      },
    ]
  },
  {
    title: 'Social networks development',
    projects: [
      {
        title: 'Web analytics app',
        backgroundImage: social1,
        location: 'Canada',
        stack: [JS, PYTHON],
        description: <div>
          <p><span>Challenge: </span>To design a tool for analyzing survey results for emotional mapping. The technology
            would add words and refer them to particular emotional groups and maintain a user-friendly interface for
            report compilation and visualization of survey results analysis.</p>
          <p><span>Result: </span>There are many implicit objective tools for gauging emotions. Although feelings are
            personal and subjective, the human brain turns them into a standard code that renders emotions through
            various feelings, situations, and even people. The segmentation of emotions was developed based on the
            classification of emotions by W. Gerrod Parrott of Georgetown University and was implemented in the product.
            The system helps companies do a precise, thorough analysis of emotional factors of behavior and understand
            their employees, clients, and patients better.
          </p>
          <p>The developed application allows uploading data and obtaining various reports in the form of graphs and
            tables. It enables us to understand and analyze emotions, Heartbeat processes language (unstructured text)
            that people use to describe their conscious feelings. The algorithm takes this textual data and converts it
            into a binary code representing primary and secondary emotions.
          </p>
          <p>Heartbeat intellectual text analytics is based on comprehensive taxonomy, the science of classification of
            over 20,000 multigrams, or emotional words and phrases, professionally encoded into 100 secondary
            categories. Each word and phrase represent one or several secondary emotional categories.</p>
        </div>
      },
      {
        title: 'Web-platform for Reunion of Educational Programs Alumni',
        backgroundImage: social2,
        location: 'USA',
        stack: [REACT, PYTHON],
        description: <div>
          <p><span>Challenge: </span>The task was to integrate a large-scale online video-conference service onto the
            web-platform.
            The web-platform would be used for online conferencing between the Educational Programs Alumni and event
            speakers during the world-pandemic situation.
            The video-conference service would allow Speakers to stream audio/video and the attendees would be able to
            watch and listen to the lectures.
            Seamless integration was another essential requirement of the Customer. The conferences should be available
            for all authorized users without any additional registrations, redirections, or opening new tabs. All
            attendees should be able to access the panels with one click.
          </p>
          <p><span>Result: </span>The project resulted in a website featuring a one-pager "business card" for the Alumni
            Reunion. We have developed it from scratch — the Team adapted the design mockups created by our designer. A
            flawless combination of a laconic modern design and interactive, user-friendly functionality fully met the
            Customer's expectations.
            The Platform conferencing functions were realized with the use of Zoom API, and all user scenarios were
            completed in just one step. Any authorized user can register, login, join a conference, check any speaker's
            info, and see any event's gallery or any conference's record in just one click.
            The Educational Programmes’ coordinators can continue to benefit from the Platform the following years
            without any additional development since the website has an easily configurable interface.
          </p>
        </div>
      },
      {
        title: 'Photography database development',
        backgroundImage: social3,
        location: 'Kyrgyzstan',
        stack: [REACT, PYTHON],
        description: <div>
          <p><span>Challenge: </span>The task was to create a photo-sharing platform, where photographers could upload
            photos from a particular event and then offer them for sale in good quality and full resolution size. Photo
            hosting was aimed at connecting photographers and potential image buyers.
          </p>
          <p><span>Result: </span>Result: The project functionality provides photographers with the ability to upload
            pictures taken in various city locations into a single database. Having uploaded a photo gallery from a
            particular event, registered photographers can email it to clients via the plarform’s hosting. The clients
            receive photos in a small resolution and with watermarks. They can then go to the website and buy the
            original set of photographs in good quality and full size.
          </p>
        </div>
      }
    ]
  },
  {
    title: 'E-commerce systems and eMarketplaces',
    projects: [
      {
        title: 'Ecommerce Website development',
        backgroundImage: ecommerce1,
        location: 'Kazakhstan',
        stack: [JS, PYTHON],
        description: <div>
          <p><span>Challenge: </span>The customer aimed to develop a modern and user-friendly marketplace to withstand
            competition from local and foreign competitors.</p>
          <p><span>Result: </span>A website that provides users with a user-friendly browsing instrument to view
            catching offers from shops equipped with their goods- and services-promoting platforms.</p>
        </div>
      },
      {
        title: 'Development of a web application with Amazon platform integration',
        backgroundImage: ecommerce2,
        location: 'USA',
        stack: [ANGULAR],
        description: <div>
          <p><span>Challenge: </span>To develop a web application that would allow eBay sellers to automatically
            synchronize selling and managing of their products on the Amazon site.
          </p>
          <p><span>Result: </span>The developed web application met all requirements of the customer. It appears to be
            highly competitive and popular among the local target audience.
          </p>
        </div>
      },
      {
        title: 'Mobile application marketplace',
        backgroundImage: ecommerce3,
        location: 'Central Asia',
        stack: [PYTHON, ANDROID],
        description: <div>
          <p><span>Challenge: </span>To create a marketplace application from scratch, with complex search logic, using
            Google search engine and Instagram application as a reference.</p>
          <p><span>Result: </span>This marketplace app has a highly accurate search engine, modern design, convenient
            and intuitive interface, and the fastest and most advantageous form of ad submission. A user can add photo
            and video ads in a matter of seconds, send messages directly from the app, call up, save ads to favorites,
            customize a feed according to personal preferences, add hashtags (keywords) to the ad, and search by several
            hashtags, use advanced search to sort and fine-tune the found ads by category, ad type and price. A user can
            create an account in the application, get subscribers, and subscribe to other accounts.
          </p>
        </div>
      },
    ]
  },
  {
    title: 'Business Intelligence Systems',
    projects: [
      {
        title: 'BI app with B2B e-commerce integration',
        backgroundImage: business1,
        location: 'Israel',
        stack: [REACT],
        description: <div>
          <p><span>Challenge: </span>The project objective was to create a business intelligence application based on
            open data. ClickBank is a platform for partners offering about 10'000 products to be promoted. The project
            united partners who were looking for an opportunity to improve their products through ClickBank with a
            valuable database. The customer sought an appropriate tool to leverage custom-tailored Technologies to
            decrease costs & multiply the high-quality targeted traffic generation scale.
          </p>
          <p><span>Result: </span>A tool for creating and administering ads (active directory administration), used
            internally in a digital marketing agency's day-to-day work. We have introduced Scrum with all the relevant
            tools and processes.
          </p>
          <p>The product gathers signals from binary options generated by trading platforms and aggregated on a
            web-service. The web-service will show the dynamics of changes until the end of a user's online session.
          </p>
          <p>We've developed functionality to gather campaign data from multiple sources, uniting the data, storing it,
            displaying it, and taking actions based on the data manually and automatically.
            We've set up a server to optimize advertisement traffic among the clients willing to place an advertisement
            for their products and traffic banks.
          </p>
        </div>
      },
    ]
  },
  {
    title: 'HRM and CRM',
    projects: [
      {
        title: 'Time tracking desktop app',
        backgroundImage: hrm1,
        location: 'USA',
        stack: [REACT],
        description: <div>
          <p><span>Challenge: </span>Create an online platform that combines several automated services to track
            employees’ work. It should allow a company to manage its onsite and remote employees with an easy to use
            time-tracking tool with a clean interface.
          </p>
          <p><span>Result: </span>We have created the tool that allows time tracking of each employee in the company.
          </p>
          <p>The product allows users to select an assigned project and track how long an employee is working on it, as
            well as to add notes and comments.
          </p>
          <p>The tool displays whether the project is internal (company cost) or external (client billable). The
            application also tracks paid time off accruals and balances for each user.</p>
          <p>The system displays events from the calendar and emails from a user's email account. A visual toolbar shows
            the hours an employee has worked per day.
          </p>
        </div>
      },
      {
        title: 'Time tracking telegram bot + Softhouse HRM system',
        backgroundImage: hrm2,
        location: 'Kyrgyzstan',
        stack: [REACT, PYTHON],
        description: <div>
          <p><span>Challenge: </span>We were tasked with creating a Time Tracker Bot as part of an HRM system so that
            the company could track their employees' working hours.</p>
          <p><span>Result: </span>The HRM system has an extensive set of functions: integrated Telegram bot, working
            days sheet, vacation days sheet, contacts section, user profile section, many notifications options, and
            others.
          </p>
          <p>The bot records and summarizes the time worked per day and transmits this data to HRM, which in turn
            processes and calculates the value of the working days in the system. The HRM uses specific formulas to
            calculate vacation days depending on the work hourage.
          </p>
        </div>
      },
      {
        title: 'Report bot',
        backgroundImage: hrm3,
        location: 'Kyrgyzstan',
        stack: [REACT, NODE],
        description: <div>
          <p><span>Challenge: </span>The customer requested a bot that would provide transparent monitoring of
            employees' work, tracking team members’ progress, and automatically generating daily reports for projects
            and tasks. Another essential requirement is to integrate the bot with Jira Atlassian.
          </p>
          <p><span>Result: </span>We have developed the required tool. This bot is an excellent assistant to the
            manager, whose responsibilities include collecting reports on employees' effectiveness, preparing statements
            of work, and monitoring task execution per set schedule.
            The bot assists employees, reminding them to write a report, offering them to select a ticket from the list
            of tasks in progress, and informing them that it is time to close a particular ticket.
            The bot is very caring, and it does not text employees if they are on vacation or on a day off. The bot
            knows all employees of the company and works for their benefit. The bot is integrated with the company’s
            internal HRM and Jira Atlassian project management system and is good at gathering information.
            The bot sends notifications to employees and managers and allows daily report writing and editing. The bot
            gathers all the reports and sends them to management using a single report format. It allows roles
            management, dividing users by department, and provides access to reports for any chosen period.
          </p>
        </div>
      },
      {
        title: 'Inventory Management Platform',
        backgroundImage: hrm4,
        location: 'USA',
        stack: [PYTHON],
        description: <div>
          <p><span>Client: </span>The client is a logistics company from Baltimore, USA. As their business grew,
            they needed to optimize shipping routes and schedules. They were looking for a tool to gather actionable
            data about warehouse locations, available vehicles, item types and sizes. After doing market research,
            they concluded that they needed a custom solution.</p>
          <p><span>Challenge: </span>The platform was to be created as a web application providing the personnel
            with a secure, intuitive and efficient online platform for managing and tracking tasks and available
            resources. Besides, the platform would log the activity and generate detailed reports for the company’s
            management.</p>
          <p><span>Result: </span>We analyzed several solutions, including the one the client had in place, before
            writing down all requirements and creating a plan for how we will achieve it.</p>
          <p>Then our team developed an inventory management platform that allowed managing orders, goods and
            delivery. We used Django to ensure the backend would handle a large number of requests and stay easily
            scalable. During all stages, our team kept a robust feedback loop with the client.</p>
          <p>We allocated two frontend developers, three backend developers and a designer for this project. It took
            us four months and a half to complete the project.</p>
          <p>One of our client's major concerns was the cost of delivery. Our project helped them decrease it
            considerably by selecting better routes to customers.</p>
          <p>The key functionality of the final product:</p>
          <ul>
            <li>order processing dashboard with a bespoke CRM module</li>
            <li>fleet, personnel and shift management</li>
            <li>shipping cost calculation</li>
            <li>billing, cost & revenue analysis</li>
            <li>custom reports</li>
            <li>private APIs for bespoke 3rd-party integrations</li>
          </ul>
        </div>
      },
    ]
  }
]
