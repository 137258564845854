import React from 'react';

const Promo = () => {
  return (
    <div id="promo">
      <span className='promo-bg'/>
      <div className="container">
        <h1>
          Enjoy the journey <br/>
          to your suAPPernova
        </h1>
        <div className="link-box">
          <a href="https://www.facebook.com/AttractorSoftware"
             className="promo-link promo-link-fb"
             target='_blank'
             rel='noreferrer noopener'/>
          <a href="http://instagram.com/attractorsoftware"
             className="promo-link promo-link-ig"
             target='_blank'
             rel='noreferrer noopener'/>
          <a href="https://www.linkedin.com/company/attractor-software-llc/"
             className="promo-link promo-link-in"
             target='_blank'
             rel='noreferrer noopener'/>
          {/*<a href="#" className="promo-link promo-link-tw"/>*/}
        </div>
      </div>

    </div>
  );
};

export default Promo;
