import React from 'react';
import ContactForm from "../ContactForm/ContactForm";

const AboutUsParts = () => {
  const countries = [
    'The USA',
    'Canada',
    'Germany',
    'Great Britain',
    'Israel',
    'Kazakhstan',
  ]

  const counriesItems = countries.map((country, key) => {
    return <p className={country.replace(/\s/g, '-').toLowerCase()} key={key}>{country}</p>
  })
  const partners = [
    {
      name: 'Toptal',
      link: "/",
    },
    {
      name: 'JetBrains',
      link: "/",
    },
    {
      name: 'Python Software Foundation',
      link: "/",
    },
    // {
    //   name: 'Zensoft',
    //   link: "/",
    // },
    {
      name: 'Clutch',
      link: "/",
    },
    {
      name: 'Tengry',
      link: "/",
    },
    {
      name: 'Sametrica',
      link: "/",
    },
    // {
    //   name: 'Peklo',
    //   link: "/",
    // },
    {
      name: 'B12',
      link: "/",
    },
    {
      name: 'Backflow Prevention Services',
      link: "/",
    },
    {
      name: 'Heartbeatai',
      link: "/",
    },
    {
      name: 'Xis7',
      link: "/",
    },
    {
      name: 'Techwomen',
      link: "/",
    },
    {
      name: 'USAID',
      link: "/",
    },
    {
      name: 'Udemy',
      link: "/",
    },
    {
      name: 'Lalafo',
      link: "/",
    },
    {
      name: 'Pluralsite',
      link: "/",
    },
    // {
    //   name: 'TopDeveloper',
    //   link: "https://www.topdevelopers.co/",
    // },
    {
      name: "GoodFirms",
      link: "https://www.goodfirms.co/company/attractor-software-llc"
    },
    {
      name: "Techreviewer",
      link: "https://techreviewer.co/companies/attractor-software-llc"
    },
    {
      name: "Python Development Companies",
      link: "https://superbcompanies.com/categories/python-development-companies/"
    },
    {
      name: "ReactJS",
      link: "/"
    },
    {
      name: "Web Development",
      link: "/"
    },
    {
      name: "Mobile App Development",
      link: "/"
    },
    {
      name: "App Development Companies",
      link: "https://appdevelopmentcompanies.co/local-agencies/us/app-developers/texas"
    },
    {
      name: "Global Awards",
      link: "/"
    },
    {
      name: "Top Clutch",
      link: "/"
    }
  ]
  const partnersItems = partners.map((partner, key) => {
    return <p onClick={() => partnersBtn(partner.link)} className={`partner-item-${partner.name.replace(/\s/g, '-').toLowerCase()}`} key={key}>{partner.name}</p>
  })

  const partnersBtn = (link) => {
    if (link !== "/"){
      window.open(link,"_blank");
    }
  }


  return (
    <div id="about-us">
      <div className="about-us-upper wow fadeInUp"
           data-wow-offset="200"
           data-wow-duration='0.8s'>
        <div className="container">
          <div className="company-description">
            <h3>About us</h3>
            <p>
              Attractor Software LLC has been providing Custom Software
              Development as outsourcing service since 2012, for projects of
              varying complexity and scale - from creating web-forms for
              questionnaires and data analysis visualization to developing
              and integrating e-commerce applications and financial
              technologies systems.
            </p>
            <p>
              Our experience and a long record of successful collaborations
              with clients allow us to deliver a high-quality product that
              meets your requirements.
            </p>
            <p>
              We like what we do and do it well.
            </p>
          </div>
          <div className="countries  wow fadeInUp"
               data-wow-delay='0.5s'
               data-wow-offset="200"
               data-wow-duration='0.8s'>
            <h4>We Are A Trusted Partner And A Business Assistant To Our Clients All Over The Globe</h4>
            <div className="countries-box">
              {counriesItems}
            </div>
          </div>
        </div>
      </div>

      <div className="about-us-bottom  wow fadeInUp"
           data-wow-duration='0.8s'>
        <div className="container">
          <div className="contact-us wow fadeInUp"
               data-wow-delay='0.5s'
               data-wow-duration='0.8s'>
            <h4>Contact us</h4>
            <div className="form">
              <ContactForm parentBlockName="about-us"/>
            </div>
          </div>

          <div className="partners">
            <span/>
            <div className="partners-inner wow fadeInUp">
              <h4>Our Clients and partners</h4>
              <div className="partners-items-box">
                {partnersItems}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsParts;
