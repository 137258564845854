import React from 'react';
import PropTypes from 'prop-types';

const FormElement = (props) => {

  const field = props.isTextarea ?
    <textarea
        required
      id={props.name + '-' + props.parentBlockName}
      name={props.name}
      cols="10"
      rows="5"
      value={props.value}
      onChange={props.onChange}
      placeholder={props.title}
      onBlur={props.onBlur}
      className={`form-field ${props.className}`}
    /> :
    <input
        required
      onClick={props.onClick}
      id={props.name + '-' + props.parentBlockName}
      type={props.type}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={props.isLabeled ? '' : props.title}
      className={`form-field ${props.className}`}
    />;

  const label = <label
    htmlFor={props.name + '-' + props.parentBlockName}
    className="form-field-label"
  >{props.title}</label>;

  const message = <span
    className={`input-message`}>{props.message}</span>
  return (
    <div className="form-group">
      {field}
      {label}
      {message}
    </div>
  );
};

FormElement.propTypes = {
  isLabeled: PropTypes.bool,
  isTextarea: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  message: PropTypes.string,
  showMessage: PropTypes.bool,
  parentBlockName: PropTypes.string
};

export default FormElement;
