import React, {useState} from 'react';
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com';
import {init} from 'emailjs-com';
import 'react-phone-input-2/lib/style.css';
import {
  INVALID_ABOUT_PROJECT,
  INVALID_EMAIL,
  INVALID_NAME,
  JOIN_US,
  MAIL_SERVICE_URL,
  SITE_KEY,
  SERVICE_ID,
  USER_ID,
  TEMPLATE_ID,
  INVALID_LINK_TO_CV
} from "../../constants";
import {
  addClassToReactTelInputByParentBlock,
  doesStringContainNonASCII,
  highLightInput,
  isDescriptionValid,
  isEmailValid,
  isLinkToCVValid,
  isNameValid,
  isPhoneValid, removeNonASCIICharacters
} from "../../utils/helpers";
import FormElement from "../UI/FormElement/FormElement";

const ContactForm = (props) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [about_project, setAboutProject] = useState('');
  const [link_to_cv, setLinkToCV] = useState('');
  const [file, setFile] = useState(null);
  const [reCaptcha, setReCaptcha] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isFormShown, setIsFormShown] = useState(true);

  const sendData = data => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      const value = data[key];
      formData.append(key, value);
    });

    return axios.post(MAIL_SERVICE_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  const fileChangeHandler = e => {
    const {files} = e.target;
    if (files.length > 0) {
      setFile(files[0])
    } else {
      setFile(null);
    }
  }

  const isReCaptcha = (value) => {
    if (value !== null) return setReCaptcha(true);
  }

  const submitForm = (event) => {
    event.preventDefault();
    const data = {name, email, phone, about_project, link_to_cv, file};
    setIsFormShown(false);
    setIsLoading(true);

    if (props.type === 'JOIN_US') {
      init(USER_ID);
      emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID).then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
        }
      });
    } else {
      sendData(data)
        .then(response => {
          if (response.status === 200) {
            setIsLoading(false);
          }
        });
    }
  }

  const isFormValid = () => (
    props.type === JOIN_US ?
      isNameValid(name) &&
      isEmailValid(email) &&
      isPhoneValid(phone) &&
      isDescriptionValid(about_project) &&
      isLinkToCVValid(link_to_cv) &&
      reCaptcha
      :
      isNameValid(name) &&
      isEmailValid(email) &&
      isPhoneValid(phone) &&
      isDescriptionValid(about_project) &&
      reCaptcha

  )


  document.addEventListener("DOMContentLoaded", function (event) {
    try {
      const labels = document.getElementsByClassName('form-field-phone');
      for (let label of labels) {
        label.parentNode.insertBefore(label, label.parentNode.firstChild);
      }

      addClassToReactTelInputByParentBlock('about-us');
      addClassToReactTelInputByParentBlock('lets-talk');

    } catch (error) {
      console.log(error);
    }
  })


  const form = <form className={isFormShown ? '' : 'form-hide'}>
    {props.type === JOIN_US ? <p className="join-us-text">
      We are always looking for talented IT specialists to join our team.
      Please fill out the form below and someone will get back to you.
    </p> : ''}
    <FormElement
      type="text"
      isTextarea={false}
      name="name"
      value={name}
      parentBlockName={props.parentBlockName}
      onChange={(event) => {
        highLightInput(`name-${props.parentBlockName}`, isNameValid(event.target.value));
        setName(event.target.value);
      }}
      onBlur={(event) => {
        highLightInput(`name-${props.parentBlockName}`, isNameValid(event.target.value));
      }}
      title="Full name*"
      message={INVALID_NAME}
    />

    <FormElement
      type="email"
      isTextarea={false}
      name="email"
      value={email}
      onClick={() => {
        setEmailWarning(true)
      }}
      parentBlockName={props.parentBlockName}
      onChange={(event) => {
        highLightInput(`email-${props.parentBlockName}`,
          !(doesStringContainNonASCII(event.target.value) || !isEmailValid(event.target.value)));
        setEmail(removeNonASCIICharacters(event.target.value));
        setEmailWarning(false)
      }}
      onBlur={(event) => {
        if (emailWarning) {
          highLightInput(`email-${props.parentBlockName}`, isNameValid(event.target.value))
        }
      }}

      title="E-mail*"
      message={INVALID_EMAIL}
    />

    <PhoneInput
      value={phone}
      enableSearch={true}
      onClick={(e) => {
        e.target.parentElement.classList.add(`react-tel-input-${props.parentBlockName}`)
      }}
      onChange={(phoneValue) => {
        setPhone(phoneValue);
        highLightInput(`phone-${props.parentBlockName}`, isPhoneValid(phoneValue));

        if (isPhoneValid(phoneValue)) {
          document.styleSheets[0].addRule(`.react-tel-input-${props.parentBlockName}::before`, 'background-color: #FFFFFF !important');
          document.styleSheets[0].addRule(`.react-tel-input-${props.parentBlockName} > .flag-dropdown`, 'background-color: #FFFFFF !important');
        } else {
          document.styleSheets[0].addRule(`.react-tel-input-${props.parentBlockName}::before`, 'background-color: #FFEEEE !important');
          document.styleSheets[0].addRule(`.react-tel-input-${props.parentBlockName} > .flag-dropdown`, 'background-color: #FFEEEE !important');
        }
      }}

      onBlur={(event) => {
        highLightInput(`phone-${props.parentBlockName}`, isNameValid(event.target.value))

        if (event.target.value) {
          document.styleSheets[0].addRule(`.react-tel-input-${props.parentBlockName}::before`, 'background-color: #FFFFFF !important');
          document.styleSheets[0].addRule(`.react-tel-input-${props.parentBlockName} > .flag-dropdown`, 'background-color: #FFFFFF !important');
        } else {
          document.styleSheets[0].addRule(`.react-tel-input-${props.parentBlockName}::before`, 'background-color: #FFEEEE !important');
          document.styleSheets[0].addRule(`.react-tel-input-${props.parentBlockName} > .flag-dropdown`, 'background-color: #FFEEEE !important');
        }
      }}

      specialLabel="Country code and phone number*"
      inputProps={{
        name: 'phone',
        id: `phone-${props.parentBlockName}`,
        className: "form-field form-field-phone"
      }}
    />

    <FormElement
      type="text"
      isTextarea={true}
      name="about_project"
      value={about_project}
      parentBlockName={props.parentBlockName}
      onChange={(event) => {
        setAboutProject(event.target.value);
        highLightInput(`about_project-${props.parentBlockName}`, isDescriptionValid(event.target.value));
      }}

      onBlur={(event) => {
          highLightInput(`about_project-${props.parentBlockName}`, isDescriptionValid(event.target.value));
      }}

      title={props.type === JOIN_US ? "Covering letter*" : "Project description*"}
      message={INVALID_ABOUT_PROJECT}
    />

    {props.type === JOIN_US ?
      <FormElement
        type="url"
        isTextarea={false}
        name="link_to_cv"
        value={link_to_cv}
        parentBlockName={props.parentBlockName}
        onBlur={(event) => {
          highLightInput(`link_to_cv-${props.parentBlockName}`, isNameValid(event.target.value));
        }}
        onChange={(event) => {
          highLightInput(`link_to_cv-${props.parentBlockName}`,
            !(doesStringContainNonASCII(event.target.value) || !isLinkToCVValid(event.target.value)));
          setLinkToCV(removeNonASCIICharacters(event.target.value));
        }}

        title={"Link to CV*"}
        message={INVALID_LINK_TO_CV}
      /> : ''
    }

    <ReCAPTCHA
      className="reCaptcha"
      sitekey={SITE_KEY}
      onChange={isReCaptcha}
    />

    <div className={`form-actions-group ${props.btnWidth ? "btnWidth" : ""}`}>
          <button
              type="submit"
              className={`btn btn-success btn-lg submit-button ${!isFormValid() ? 'submit-button-disabled' : ''}`}
              id="submit-button-about-block"
              onClick={submitForm}
              disabled={!isFormValid()}
          >
            Submit
          </button>


      {props.type === JOIN_US ? '' : <label className="form-file-field" htmlFor={`attachment-${props.parentBlockName}`}>
        {!!file ? file.name : 'Add file attachment'}

        <input type="file" id={`attachment-${props.parentBlockName}`} name="file" onChange={fileChangeHandler}/>
      </label>}
    </div>
  </form>

  const thankYouMessage = <div
    className={`form-message ${(!isFormShown && !isLoading) ? 'form-message-show' : ''}`}>
    Thank you! <br/>We will answer you soon!
  </div>

  const preloader = <div className={`form-preloader ${isLoading ? '' : 'preloader-hide'}`}/>


  return (
    <div>
      {form}
      {preloader}
      {thankYouMessage}
    </div>
  );
};

export default ContactForm;
