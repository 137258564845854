import React from 'react';
import {scrollToElementCenter} from "../../utils/helpers";

const Pricing = () => {
  return (
    <div className="pricing-block">
      <h4>Pricing tiers</h4>

      <div className="container">
        <div className="pricing-box">

          <div className="pricing-item pricing-item-1 wow fadeInUp"
               data-wow-delay='0s'
               data-wow-offset="120"
               data-wow-duration='0.8s'>
            <h5>Developer</h5>
            <div className="pricing-info">
              <p>You get an experienced professional Programmer - on a dedicated timeline.</p>
              <p>Ideal option for a small-scale project.</p>
            </div>

            <p>From $720</p><span>per week</span>
          </div>

          <div className="pricing-item pricing-item-2 wow fadeInUp"
               data-wow-delay='0.3s'
               data-wow-offset="120"
               data-wow-duration='0.8s'>
            <h5>Developer designer<span>+</span></h5>
            <div className="pricing-info">
              <p>Get an experienced Developer for perfect functionality, plus a creative Designer for outstanding user
                interface/experience.</p>
              <p>Have your MVP built and shipped!</p>
            </div>
            <p>From $1,440</p><span>per week</span>
          </div>

          <div className="pricing-item pricing-item-3 wow fadeInUp"
               data-wow-delay='0.6s'
               data-wow-offset="120"
               data-wow-duration='0.8s'>
            <h5>2 developers project manager<span>+</span></h5>
            <div className="pricing-info">
              <p>We recommend this team for taking on more complex tasks and scaling up your project, while helping you
                stay within budget.</p>
              <p>Rent a team.</p>
            </div>
            <p>From $1,580</p><span>per week</span>
          </div>

          <div className="pricing-item pricing-item-4 wow fadeInUp"
               data-wow-delay='0.9s'
               data-wow-offset="120"
               data-wow-duration='0.8s'>
            <h5>Dedicated development team</h5>
            <div className="pricing-info">
              <p>We will provide you with a dedicated team, formed according to specific requirements of your
                project.</p>
            </div>
            <p className="get-quote-btn" onClick={() => scrollToElementCenter('contact-us')}>Get a quote</p>
          </div>

        </div>
      </div>

    </div>
  );
};

export default Pricing;
