import React from 'react';
import logo from "../../assets/images/attractor-logo-stroke.svg";

function Loading(){
    return (
        <div className="loading loading_center">
            <img src={logo} alt="load"/>
        </div>
    );
}

export default Loading;
