import React from 'react';
import {Nav, Navbar} from "react-bootstrap";
import {NavLink, withRouter} from "react-router-dom";
import {ReactComponent as MenuIcon} from '../../assets/images/menu-icon.svg';

const navItems = [
  {path: '/case-studies', label: 'Case Studies'},
  {path: '/processes', label: 'Processes'},
  {path: '/about', label: 'About Us'}
]

const sentinelId = "navbarSentinel";

export const NavbarSentinel = () => {
  return (
    <div id={sentinelId}/>
  )
}

export class MainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {isDarkMode: false, isFixedMode: false, isExpanded: false}

    this.setSentinelPosition = this.setSentinelPosition.bind(this)
    this.checkScrollPosition = this.checkScrollPosition.bind(this)
    this.onToggle = this.onToggle.bind(this)
    this.collapseMenu = this.collapseMenu.bind(this)
    this.onContactUs = this.onContactUs.bind(this)
  }

  componentDidMount() {
    this.setSentinelPosition()
    this.checkScrollPosition()
    document.addEventListener('scroll', this.checkScrollPosition)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setSentinelPosition()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.checkScrollPosition)
  }

  setSentinelPosition() {
    try {
      const sentinel = document.getElementById(sentinelId)
      this.sentinelPos = 500

      if (sentinel) {
        this.sentinelPos = sentinel.getBoundingClientRect().top + window.scrollY
      }
    } catch (error) {
      console.log(error);
    }

  }

  checkScrollPosition() {
    if (this.state.isExpanded) {
      return
    }

    const isDark = window.scrollY > this.sentinelPos / 2
    if (this.state.isDarkMode !== isDark) {
      this.setState({isDarkMode: isDark})
    }

    const isFixed = window.scrollY > this.sentinelPos
    if (this.state.isFixedMode !== isFixed) {
      this.setState({isFixedMode: isFixed})
    }
  }

  onToggle(expanded) {
    if (expanded) {
      this.setState({isExpanded: true, isDarkMode: true, isFixedMode: true})
    } else {
      this.collapseMenu();
    }
  }

  collapseMenu() {
    this.setState({isExpanded: false})
    setTimeout(() => {
      this.checkScrollPosition()
    }, 300) // Time to transition to transparent mode, after menu closes.
  }

  onContactUs() {
    this.collapseMenu()
    try {
      document.getElementById('contact-us').scrollIntoView({behavior: "smooth", block: "center"})
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <Navbar variant="dark" expand="lg" fixed="top" onToggle={this.onToggle} expanded={this.state.isExpanded}
              className={`main-navbar ${this.state.isDarkMode ? 'dark-mode' : ''} ${this.state.isFixedMode ? 'fixed-mode' : ''}`}>
        <Navbar.Brand href="/" className="position-relative">
          <div className="navbar-logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="p-0">
          <div className="btn btn-success"><MenuIcon/></div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {navItems.map(item => (
              <Nav.Item key={item.path} className="mr-4 my-2 my-lg-0 navbar-link">
                <NavLink rel="preload" to={item.path} exact={true} onClick={this.collapseMenu}> {item.label} </NavLink>
              </Nav.Item>
            ))}
            <a href="https://blog.attractor-software.com/" target="blank" rel="noreferrer"
               className="mr-4 my-2 my-lg-0 navbar-link">Blog</a>

            <Nav.Item className="my-3 my-lg-0">
                <span onClick={this.onContactUs} className="btn btn-outline-success">
                  Contact Us
                </span>
            </Nav.Item>
          </Nav>

          <div className="d-none d-lg-flex flex-lg-wrap-reverse flex-lg-row-reverse ">
            <div className="d-inline-flex ml-3">
              <span className="icon nav-contact-item-tg"/>
              <div>
                <a target="_blank" href="https://t.me/Attractorsoftware" rel="noreferrer" className="d-block navbar-hover">attractorsoftware</a>
              </div>
            </div>

            <div className="d-inline-flex ml-3">
              <span className="icon nav-contact-item-phone"/>
              <div>
                <a href="tel:+1 855 333 6827" className="d-block navbar-hover">+1 855 333 6827</a>
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default withRouter(MainNavbar)
