export const MAIL_SERVICE_URL = 'https://5vw7lo.on.it-attractor.com/send';
export const HOST_URL = 'https://www.attractor-software.com';
export const SITE_KEY = '6LfJfHYaAAAAAH_DON2R5yLoa31puDhv2my-GWFG';

export const SERVICE_ID = 'service_ojw6x3l';
export const USER_ID = 'user_xZBxaCJFiIcgJX0zmkO2u';
export const TEMPLATE_ID = 'template_8jeme5f';

export const INVALID_NAME = 'Please enter your name';
export const INVALID_EMAIL = 'Please enter valid email';
export const INVALID_ABOUT_PROJECT = 'Please enter project description';
export const INVALID_LINK_TO_CV = 'Please enter link to CV';

export const PROCESS = 'process';
export const PROJECT = 'project';

export const JOIN_US = 'JOIN_US';

export const REACT = 'ReactJS';
export const PHP = 'PHP';
export const ANGULAR = 'Angular';
export const IOS = 'iOS';
export const ANDROID = 'Android';
export const RUBY = 'Ruby';
export const JS = 'JS';
export const PYTHON = 'Python';
export const KUBERNETES = 'Kubernetes';
export const KUBEFLOW = 'Kubeflow';
export const NODE = 'NodeJS';
export const FLUTTER = 'Flutter';
export const GOLANG = 'GoLang';

export const MAIN = '/';
export const MAIN_HIRE = '/app-developers-for-hire';
export const CASE_STUDIES = "/case-studies";
export const PROCESSES = "/processes";
export const ABOUT = "/about";


